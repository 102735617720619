import './App.css';
import ReactPlayer from "react-player"
import Ticker from 'react-ticker'




function App() {
  return (
    <div className="App">
                <header className="App-header">
                <h1> nikstebob </h1>
                </header>
      
      <div className="vid">
      <ReactPlayer url="https://vimeo.com/690490134" controls
      width="400px"
      heigth="100px"
      playing="true"/>
      </div>
      <header className="App-footer" >
      <h1></h1>
      </header>
    </div >
  );
}

// git commit -a -m 'hei'
// git push -u -f origin main


export default App;
